import { useState, useEffect, useRef } from 'react'
import { card, importAll } from './Components/imageImporter'
import Projects from './Components/Projects'
import About from './Components/About'
import './App.css'
function App() {
  const [load, setLoad] = useState(0)
  const [mode, setMode] = useState(true)
  const [campaignMode, setCampaignMode] = useState(false)
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark')
  const themeMem = useRef(theme)
  const [projects, setProjects] = useState([])
  const idGenerator = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
        }
        return result;
    }
  
  useEffect(() => {
    if (load < 81){
      let delay = Math.random()* 200
      setTimeout(() => {
        setLoad(load + 1)
      }, delay)
    }
    else if (load == 81){
      let delay = Math.random()* 200
      setTimeout(() => {
        setLoad(100)
      }, delay)
    }
  }, [load])

  useEffect(() => {
    localStorage.setItem('theme', theme)
    document.body.className = localStorage.getItem('theme');
  }, [theme])

  class Project{
        constructor(title, description, liveLink, github, vidSrc, coverSrc, bwCover, titleColors){
            this.title = title
            this.description = description
            this.liveLink = liveLink
            this.github = github
            this.vidSrc = vidSrc
            this.coverSrc = coverSrc
            this.bwCover = bwCover
            this.titleColors = titleColors
            this.id = idGenerator(10)
        }
    }
    useEffect(() => {
        const titles = ['Euphonia', 'Acrolix', 'The Game', "Portfolio"]
        const descriptions = [
            {oneLiner:'Find your next favourite tune.', pitch: "Do you like the sweet sound of synthesizers? Does electronic music make your heart sing?/Are you in love? Are you heartbroken?/Checkout Euphonia."}
            ,{oneLiner:'Chaotic Word Play.', pitch: "Looking for a catchy name for your business? a memorable slogan for your brand?/Acrolix is the perfect tool./Play with creative backronyms with a fun sleek interface in just a few taps."}
           ,{oneLiner:'Come watch The Game with us.', pitch:'Follow the stats of your favourite FIFA team on The Game.'}, {oneLiner:'Buttery Smooth Motion Development.', pitch:'Curious how this porfolio was built?/Check out the code.'}
        ]
        const liveLinks =[
            'https://euphonia3.vercel.app/',
            'https://acrolix.netlify.app/',
            'https://thegame101.netlify.app',
            'https://samjaberi.dev/'
        ]
        const githubs = [
            'https://github.com/samnjab/euphonia3',
            'https://github.com/samnjab/Acrolix',
            'https://github.com/samnjab/theGame',
            'https://github.com/samnjab/new-portfolio'
        ]
        const videos = importAll(require.context('./assets', false, /\.(mp4)$/))
        const videoSrcs = {
            'euphonia': card('euphonia', 1, videos),
            'acrolix': card('acrolix', 1, videos),
            'thegame': card('thegame', 1, videos),
            'portfolio': card('portfolio', 1, videos)
        }
        const images = importAll(require.context('./assets', false, /\.(png|jpe?g|svg)$/) );
        const coverSrcs = 
        { 
            'euphonia': [card('euphonia', 1, images), card('euphonia', 2, images)],
            'acrolix': [card('acrolix', 1, images), card('acrolix', 2, images)], 
            'thegame': [card('thegame', 1, images), card('thegame', 2, images)], 
            'portfolio': [card('portfolio', 1, images), card('portfolio', 2, images)]
        }
        const titleColors = [['#e0afa0', '#f4f3ee', '#bcb8b1', '#8a817c', '#463f3a'], ['#177e89', '#efe6dd', '#f3dfa2', '#bb4430', '#231f20'], ['#fffcf2', '#ccc5b9', '#403d39', '#252422', '#eb5e28'], ['#6b705c', '#ddbea9', '#ffe8d6', '#b7b7a4', '#a5a58d', '#6b705c']]
        setProjects(
            titles.map((title, i) => {
                let project = new Project(title, descriptions[i], liveLinks[i], githubs[i], videoSrcs[title.toLowerCase().replace(' ', '')], coverSrcs[title.toLowerCase().replace(' ', '')][0], coverSrcs[title.toLowerCase().replace(' ', '')][1], titleColors[i])
                return project
            })
        )
    },[])
  return (
    <div className="App">
      <div className='transition-background'></div>
      {
        load < 100 ?
         <header className="App-header">
            <div className='wrapper'>
                <h1>{load}</h1>
            </div>
          </header>
          :
          <>
          <header className={campaignMode ? 'campaignHeader' : 'App-header'}>
              <div className='wrapper'>
                  <h1>
                      <span><span>S</span></span>
                      <span><span>a</span></span>
                      <span><span>m</span></span>
                      <span><span>J</span></span>
                  </h1>
                  <div className='theme'>
                    {
                      theme === 'dark' ?
                      <button 
                      className='lightTheme'
                      onClick={() => {
                        setTheme('light')
                        themeMem.current = 'light'
                      }}
                      >
                      <div className="theSun">
                    </div>
                      </button>
                      :
                      <button 
                      className='darkTheme'
                      onClick={() => {
                        setTheme('dark')
                        themeMem.current = 'dark'
                      }}
                      >
                        <div className='moon'></div>
                      </button>
                    }
                  </div>
                  <nav>
                      <a 
                      className='nav'
                      onClick={() => {
                        setMode(!mode)
                        setTheme(themeMem.current)
                      }}
                      >
                          {
                          mode ?
                          <p>About</p>
                          :
                          <p>Projects</p>
                          }
                      </a>
                  </nav>
              </div>
            </header>
            <section className={`main ${campaignMode ? 'campaignMain' : ''} ${!mode ? 'aboutMain': '' }`}>
              {
                mode ?
                <Projects mode={mode} projects={projects} setCampaignMode={setCampaignMode} campaignMode={campaignMode} theme={theme} setTheme={setTheme} themeMem={themeMem}/>
                :
                <About mode={mode} setMode={setMode}/>
              }
            </section>
            {
              campaignMode ? 
              <></>
              :
              <footer>
                  <div className='wrapper'>
                      <div className='intro'>
                          <p>Developer</p>
                          <p>Available May.2024</p>
                      </div>
                      <div className='contact'>
                          <a href='https://github.com/samnjab'>Github</a>
                          <a href='https://www.linkedin.com/in/sam-jaberi-310b92257/'>LinkedIn</a>
                          <a href='https://medium.com/@samanjaberi.sj'>Medium</a>
                      </div>
                  </div>
              </footer>
            }
          </>
      }
      
    </div>
  );
}

export default App;
