import { useState, useEffect, useRef } from 'react'
import Campaign from './Project'
export default function Projects({ projects, campaignMode, setCampaignMode, theme, setTheme, themeMem }) {
    const [focus, setFocus] = useState([])
    const [x, setX] = useState(0)
    const xRef = useRef(0)
    const [fullview,setFullView] = useState()
    const [trackPosition, setTrackPosition] = useState(0)
    const trackXRef = useRef(0)
    const [y, setY]= useState(0)

    useEffect(() => {
        if (!campaignMode) return
        const campaigns = document.querySelector('.campaigns')
        campaigns.addEventListener('scroll', () => {
            setY(campaigns.scrollTop)
        }, true)
    }, [campaignMode])
    const handleFocus = (project) => {
        const order = [...projects]
        for (let i=0;i < projects.indexOf(project); i++){
            order.push(order.shift(order[i]))
        }
        setFocus(order)
    }

    useEffect(() => {
        if (focus.length === 0) return
        setCampaignMode(true)
    }, [focus])
    // Track Scroll Logic
    useEffect(() => {
        if (focus.length !== 0 && campaignMode) return
        const track = document.getElementById('projectTrack')
        window.onmousedown = e => {
            track.dataset.mouseDownAt = e.clientX
        }
        window.onmousemove = e => {
            if(track.dataset.mouseDownAt === "0") return
            const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX 
            const maxDelta = window.innerWidth / 2 
            const percentage = (mouseDelta / maxDelta) * -100
            const nextPercentageUnconstrained = parseFloat(track.dataset.prevPercentage) + percentage
            const nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100)
            track.dataset.percentage = nextPercentage
            track.animate({
                transform: `translate(${nextPercentage}%, -50%)`
            }, { duration: 1200, fill: "forwards" })
            for(const image of track.getElementsByClassName('image')) {
                image.animate({
                objectPosition: `${100 + nextPercentage}% center`
                }, { duration: 1200, fill: "forwards" });
            }
        }
        window.onmouseup =() => {
            track.dataset.mouseDownAt = '0'
            track.dataset.prevPercentage = track.dataset.percentage
        }
        const section = document.querySelector('.projects')
        section.onscroll = (e) => {
            const scrollDelta = parseFloat(track.dataset.prevScroll) - e.target.scrollLeft
            const maxScrollDelta = window.innerWidth 
            const percentage = (scrollDelta / maxScrollDelta) * 100
            const nextPercentageUnconstrained = parseFloat(track.dataset.percentScroll) + percentage
            const nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100)
            track.dataset.percentScroll = nextPercentage
            track.dataset.prevScroll = e.target.scrollLeft 
            for(const image of track.getElementsByClassName('image')) {
                image.animate({
                objectPosition: `${(100 + nextPercentage)}% center`
                }, { duration: 1200, fill: "forwards" });
            }
    }
    },[campaignMode, fullview])

    useEffect(() => {
        if (focus.length === 0 && !campaignMode) return
        const campaigns = document.querySelector('.campaigns')
        campaigns.scrollLeft += (campaigns.scrollWidth - document.body.clientWidth) / 2
        xRef.current = campaigns.scrollLeft
        campaigns.onscroll = () => {
            setX(campaigns.scrollLeft)
        }
    }, [campaignMode, fullview])

    useEffect(() => {
        if (x - xRef.current < 2 && x - xRef.current > -2) return
        setCampaignMode(false)
        setFocus([])
        setFullView()
        setTheme(themeMem.current)
    }, [x])

    useEffect(() => {
        if (campaignMode) return
        if (!fullview){
            setTheme(themeMem.current)
            return
        }
        themeMem.current === 'light' ? setTheme('dark') : setTheme('light')
        const projectDiv = document.querySelector('.projects')
        const projectTrack = document.getElementById('projectTrack')
        const index = projects.indexOf(fullview)
        projectTrack.animate({
                transform: `translate(${-1 * projectTrack.dataset.prevScroll}, -50%)`
            }, { duration: 1200 , fill: "forwards" })
        projectDiv.scrollLeft =  0.5 * document.body.clientWidth - 0.16 * document.body.clientWidth + index * (document.body.clientWidth - 0.16 * document.body.clientWidth)
        trackXRef.current = projectDiv.scrollLeft
        projectDiv.onscroll = () => {
            setTrackPosition(projectDiv.scrollLeft)
            projectDiv.scrollLeft = trackXRef.current
            projectTrack.animate({
                transform: `translate(0%, -50%)`
            }, { duration: 1200 , fill: "forwards" })
        }
        const inFullView = document.getElementById(fullview.id)
        inFullView.querySelectorAll('h3').forEach(letter => {
            letter.classList.add('textAnimator')
        })
    }, [fullview])

    useEffect(() => {
        if (trackPosition === 0) return
        if (Math.abs(trackPosition - trackXRef.current) < 2 ) return
        setFullView()
        const projectTrack = document.querySelector('.projects')
        projectTrack.scrollLeft = 0
    }, [trackPosition])

    const expand = (project) => {
        const container = document.querySelectorAll('.projectContainer')[projects.indexOf(project)]
        const titleDiv = container.querySelector('.projectTitle')
        titleDiv.animate({
            transform: `translate(${0}%, -50%)`
        }, { duration: 1200, fill: "forwards" })
       
        setFullView(project)
    }

    return(
        <>
            {   focus.length !== 0 ?
                projects.length !== 0 ?
                <div className='campaigns'>
                    {     
                    focus.map(project => {
                        return <Campaign project={project} y={y}/>
                    })
                    }
                </div>
                :
                <></>
                :
                <>
                    <div className='projects'>
                        <div id='projectTrack' className={`${fullview ? 'expanded' : ''}`} data-mouse-down-at='0' data-prev-percentage='0' data-prev-scroll='0' data-percent-scroll='0'>
                            { projects.map(project => {
                                return (
                                    <div className={`window ${fullview === project ? 'rgbCover' : fullview ? 'fadedCover' :'bwCover'}`} id={project.id} key={project.id}>
                                        <div 
                                        className={`projectContainer ${fullview === project ? 'rgbCover' : fullview ? 'fadedCover' :'bwCover'}`} 
                                        >
                                            <div className={`projectTitle ${fullview === project ? '' : 'hide'}`}>
                                                {
                                                    [...project.title].map(letter => {
                                                        return(
                                                            <h3 
                                                            style = {{color:project.titleColors[Math.floor(Math.random() * project.titleColors.length)]}} 
                                                            // className={`${fullview === project ? 'textAnimator' : ''}`}
                                                            >{letter}</h3>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='img-box'>
                                                    <img 
                                                    src={project.coverSrc} 
                                                    className={`image ${fullview === project ? '' : 'hide'}`} 
                                                    draggable='false'
                                                    onClick={() => {
                                                    handleFocus(project)
                                                    }}
                                                    ></img>
                                                    <div 
                                                    className={`exploreTag ${fullview === project ? '' : 'hide'}`}
                                                    onClick={() => {
                                                    handleFocus(project)
                                                    }}
                                                    >
                                                        <p>Explore</p>
                                                        <div id='expand'><p>+</p></div>
                                                    </div>
                                                    <img 
                                                    src={project.bwCover} 
                                                    className={`image ${fullview !== project ? '' : 'hide'}`} 
                                                    draggable='false'
                                                    onClick={() => expand(project)}
                                                    ></img>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>
            }
        </>
    )

}