import { useState, useEffect, useRef } from'react'
export default function About({mode, setMode}) {
    const sectionRefs = [useRef(null), useRef(null), useRef(null)]
    const positionRefs = [useRef(0), useRef(0), useRef(0)]
    const heights = [useRef(0), useRef(0), useRef(0)]
    const [input, setInput] = useState({name: '', email: '', message: ''})
    const [submit, setSubmit] = useState(false) 
    useEffect(() => {
        let product = true 
        if (!(sectionRefs.map(sectionRef => {
                return (product && sectionRef.current)
            }))[sectionRefs.length - 1]) {
                return 
        }
        sectionRefs.forEach((sectionRef, i) => {
            sectionRef.current.dataset.scrollPosition = sectionRef.current.offsetTop
            positionRefs[i].current = sectionRef.current.dataset.scrollPosition
        })
        let height = 0
        sectionRefs.forEach((ref, i) =>{ 
            height += ref.current.offsetHeight
            heights[i].current = height 
        })
    }, [sectionRefs])

    useEffect(() => {
        sectionRefs[0].current.style.transform = `translate3d(0px, 0px, 0px)`
        let i = 1
        while (i < heights.length){
            sectionRefs[i].current.style.transform = `translate3d(0px, ${heights[i - 1].current}px, 0px)`
            i += 1
        }
    }, [heights])
    
    const scrollBy = (e) => {
        const parentDiv = document.querySelector('.a-m')
        parentDiv.dataset.globalScrollPosition = Math.max((Math.min(parseFloat(parentDiv.dataset.globalScrollPosition) + e.deltaY, heights[heights.length - 1].current + window.innerHeight / 2)), 0)
        parentDiv.animate({
            transform: `translate3d(0px, ${-(parseFloat(parentDiv.dataset.globalScrollPosition) - positionRefs[0].current)}px, 0px)`
        }, { duration: 1400, fill: "forwards", easing:'cubic-bezier(.56,.22,.47,.9)' })
    }

    return(
        <div className='about'>
            <div className='body'>
                <div className='wrapper'>
                    <div className='scrollable' id='scrollable' onWheel = {(e) => scrollBy(e)}>
                        <div className='a-m' data-global-scroll-position = {0}>
                            <div id='a-m-intro' className='a-m-cl' ref={sectionRefs[0]} data-scroll-position={0}>
                                <p>Sam is an Interactive Digital Creater based in Toronto. She specializes in creating visual experiences with a strong focus on interaction. </p> 
                                <p> Sam is of Persian and Turkish descent, and grew up in a small town near Tehran where she excelled at mathematics and computer science. She is attending the University of Toronto where she is compeleting a Specialist degree in Mathematical Applications in Economics, as well a Computer Science Major.</p>
                                <p>Aside from being a tech creator, Sam is a music fanatic, avid writer and reader.</p>
                                <p>Few things in life however, have topped her number one obsession: turning complex problems into elegant solutions.</p>
                                <p>Sam is a graduate of Juno College of Technology (formerly HackerYou) Web Development Bootcamp. A unique combination of technical skills and a deep understanding of the human psychology drives her endless quest to uncover what makes us tick. </p> 
                            </div>
                            <div id='a-m-skills' className='a-m-cl' ref={sectionRefs[1]} data-scroll-position={0}>
                                <h3>Tech Stack</h3>
                                <p>Javascript & Interactive Design</p>
                                <p>React</p>
                                <p>Python</p>
                                <p>NodeJs</p>
                                <p>jQuery</p>
                                <p>ThreeJs</p>
                                <p>GreenSock</p>
                                <p>REST APIs</p>
                                <p>Cloud Computing</p>
                            </div>
                            <div id='a-m-contact' className='a-m-cl' ref={sectionRefs[2]} data-scroll-position={0}>
                                <h3>Get In Touch</h3>
                                <p class="intro-text">Ready to collaborate on a design project? Want Sam on your team? or just want to chat about tech, design, or anything that sparks curiosity? Fill out the form.</p>
                                 {
                                        submit 
                                        ?
                                        <p class='message'>Thank you for your inquiry! Will get back to ya soon. </p>
                                        :
                                        <></>

                                    }
                                <fieldset>
                                <form 
                                    name="contact" 
                                    onSubmit={(e) => {
                                        e. preventDefault()
                                        setInput({name: '', email: '', message: ''})
                                        setSubmit(true)
                                    }}
                                    netlify >
                                    <label htmlFor="name"class="sr-only">Your Name</label>
                                    <input type="text" id="name" name="name" placeholder="Your Name" value={input.name} 
                                    onChange={(e) => {
                                        setInput({name: e.target.value, email: input.email, message: input.message})
                                    }}/>
                                    <label htmlFor="email" className="sr-only">Your Email</label>
                                    <input type="text" id="email" placeholder="Your Email" name="email" value={input.email}
                                    onChange={(e) => {
                                        setInput({name: input.name, email: e.target.value, message: input.message})
                                    }}
                                    />
                                    <label htmlFor="message" className="sr-only">Your Message</label>
                                    <textarea type="text" id="message" placeholder="Your Message" name="message" rows="8"
                                    onChange={(e) => {
                                        setInput({name: input.name, email: input.email, message: e.target.value})
                                    }}
                                    value={input.message}
                                    ></textarea>
                                    <button type='submit'>Inquire</button>
                                </form>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}